import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { processColumns } from '../../../helpers/columns';
import ExternalDataForm from '../../ExternalDataForm/ExternalDataForm';
import ExternalDataResults from '../../ExternalDataResults/ExternalDataResults';

const view_config = {
  form: {
    formSchema: {
      properties: {
        Title: {
          type: 'string',
          title: 'Nome',
          description: 'Nome do beneficiário',
        },
        start: {
          title: 'Data Inicial',
          type: 'string',
          format: 'date',
        },
        end: {
          title: 'Data Final',
          type: 'string',
          format: 'date',
        },
      },
      required: [],
      type: 'object',
    },
    initialData: {},
    uiSchema: {
      semantic: {
        errorOptions: {
          pointing: 'above',
          size: 'small',
        },
        fluid: true,
        inverted: false,
        widths: 'equal',
      },
      'ui:order': ['Title', 'start', 'end'],
      Title: {
        'ui:classNames': 'full',
      },
      start: {
        'ui:classNames': 'half',
        'ui:options': {
          yearsRange: [2020, 2030],
          format: 'DMY',
        },
      },
      end: {
        'ui:classNames': 'half',
        'ui:options': {
          yearsRange: [2020, 2030],
          format: 'DMY',
        },
      },
      'ui:submitButtonOptions': {
        submitText: 'Filtrar',
      },
    },
  },
  display: {
    pagination: {
      paginationPerPage: 50,
    },
    columns: {
      main: [
        {
          id: 'beneficiario_nome',
          style: 'text',
          format: 'link',
          title: 'Beneficiário',
          width: '24%',
        },
        {
          id: 'beneficiario_cargo',
          style: 'text wrap',
          title: 'Cargo',
          width: '14%',
        },
        {
          id: 'diarias',
          style: 'int',
          center: true,
          title: 'Diárias',
          width: '5%',
        },
        {
          id: 'unitario',
          style: 'text wrap',
          right: true,
          title: 'Valor Unitário',
          width: '8%',
        },
        {
          id: 'total',
          style: 'text wrap',
          right: true,
          title: 'Total',
          width: '8%',
        },
        {
          id: 'start',
          style: 'text',
          title: 'Início',
          right: true,
          format: 'date',
          width: '8%',
          sortable: true,
        },
        {
          id: 'end',
          style: 'text',
          title: 'Término',
          right: true,
          format: 'date',
          width: '8%',
          sortable: true,
        },
        {
          id: 'motivo',
          style: 'text wrap',
          title: 'Motivo',
          width: '25%',
        },
      ],
    },
  },
};

const DiariasView = (props) => {
  const { className } = props;
  const [localData, setLocalData] = useState();
  const [showForm, setShowForm] = useState(true);
  const display_form = true;

  const displayForm = () => {
    setShowForm(true);
    setLocalData({});
  };

  const setDiariasData = (data) => {
    if (data?.data?.items) {
      setLocalData(data);
      setShowForm(false);
    }
  };
  const pagination = view_config?.display?.pagination;
  const paginationPerPage = pagination?.paginationPerPage;
  const columns = processColumns(view_config?.display?.columns);
  const expandRows = view_config?.display?.expandRows;
  const expandRowsType = view_config?.display?.expandRowsType;
  const expandRowsHeader = view_config?.display?.expandRowsHeader;
  const metadata = localData?.metadata;

  return (
    <div className={`block localForm diarias ${className}`}>
      {!showForm && metadata && (
        <ExternalDataResults
          data={localData.data}
          metadata={metadata}
          columns={columns}
          showForm={displayForm}
          display_form={display_form}
          expandRows={expandRows}
          expandRowsType={expandRowsType}
          expandRowsHeader={expandRowsHeader}
          paginationPerPage={paginationPerPage}
        />
      )}
      {showForm && (
        <ExternalDataForm
          {...props}
          title={'Filtro'}
          setLocalFormData={setDiariasData}
          view_config={view_config}
          endpoint={'@diarias'}
          displayCancel={true}
        />
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
DiariasView.propTypes = {
  title: PropTypes.string,
};

export default DiariasView;
